<script setup lang="ts">
import {
	computed,
	ref,
	onMounted,
	onBeforeUnmount,
	watch,
	nextTick,
} from 'vue';
import StoreApi from '@zyro-inc/site-modules/api/StoreApi';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';
import { isAppPrerendering } from '@zyro-inc/site-modules/utils/prerenderingFlags';
import BlockEcommerceProductList from '@zyro-inc/site-modules/components/blocks/ecommerce/BlockEcommerceProductList.vue';
import { useBlockEcommerceProductList } from '@zyro-inc/site-modules/components/blocks/ecommerce/useBlockEcommerceProductList';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants/siteModulesConstants';
import { useEcommerceModal } from '@zyro-inc/site-modules/components/ecommerce/modals/useEcommerceModal';
import { useEcommerce } from '@zyro-inc/site-modules/components/ecommerce/useEcommerce';
import { useSearchElementDropdown } from '@zyro-inc/site-modules/utils/useSearchElementDropdown';
import {
	EcommerceProductType,
	EcommerceCollection,
	EcommerceProduct,
	EcommerceProductVariantQuantity,
} from '@zyro-inc/site-modules/types';

import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';
import { useProductSearch } from '@zyro-inc/site-modules/use/useProductSearch';
import {
	SiteBlock,
	SiteBlocks,
	SitePage,
	SiteEcommerceSortingValue,
	EcommerceProductListScrollBehaviour,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import { getWebsiteCategoryUrl } from '@zyro-inc/site-modules/utils/ecommerce/category';
import {
	getAddToCartEventPayload,
	googleTagManagerEvent,
} from '@zyro-inc/site-modules/utils/googleTagManager';

const props = withDefaults(defineProps<{
	blockId: string;
	data: SiteBlock;
	lcp: {
		type: string;
		id: string;
	};
	ecommerceTranslations: Record<string, string>;
	currentLocale: string;
	blocks: SiteBlocks;
	isCartVisible: boolean;
	isInPreviewMode: boolean;
	isMobileView: boolean;
	isDynamicProductPageEnabled: boolean;
	isWhatsAppEnabled?: boolean
}>(), {
	currentLocale: SYSTEM_LOCALE,
	blocks: () => ({}),
	isCartVisible: false,
	isInPreviewMode: false,
	isMobileView: false,
	isDynamicProductPageEnabled: false,
});

const {
	siteId,
	ecommerceStoreId,
	hasGoogleAdsApp,
	pageData,
} = useSiteGlobal();

const {
	isLoading: isEcommerceLoading,
	legacyProductPages,
	isShoppingCartOpen,
	shoppingCartItems,
	categories,
	fetchCategories,
	setCategories,
	setIsLoading,
	setIsLoaded,
	isCategoriesLoaded,
	setShoppingCartOpen,
	setShoppingCartItems,
	setIsCheckoutLoading,
	setSelectedBookingProduct,
	setVariantsQuantity,
	setIsCategoriesLoaded,
	canAddToCart,
	dynamicPageTemplateData,
} = useEcommerceGlobal({
	blockId: props.blockId,
});
const {
	openEcommerceModal,
	closeEcommerceModal,
	setProductPreviewData,
} = useEcommerceModal();
const { initiateCheckout } = useEcommerce();
const { toggleSearchDropdown } = useSearchElementDropdown({
	blockId: props.blockId,
});

const {
	blockStyle,
	textColorVars,
	columnCount,
	productsPerPage,
	productCategoryId,
	isButtonEnabled,
	buttonDisplay,
	buttonText,
	buttonStyle,
	buttonType,
	buttonBorderWidth,
	ribbonStyle,
	imageRatio,
	productSorting,
	productSearch,
	backgroundColor,
	isCategoryListEnabled,
	imageHoverEffect,
	isFullWidth,
	isTotalProductCountShown,
	columnGap,
	rowGap,
	isButtonFullWidth,
	isListCentered,
	scrollBehaviour,
	scrollToTopStyle,
	isScrollToTopEnabled,
} = useBlockEcommerceProductList(props);

const {
	isSearchResultsLoading,
	searchTerm,
	formattedSearchResults,
	updateSearchTerm,
} = useProductSearch();

const currentPage = ref(1);
const products = ref<EcommerceProduct[]>([]);
const totalProductCount = ref(0);
const currentCategoryId = ref(productCategoryId.value);
const variantsQuantity = ref<EcommerceProductVariantQuantity[]>([]);
const sorting = ref<SiteEcommerceSortingValue>(
	props.data.productSorting?.enabled
		? props.data.productSorting?.sortingOptions?.find(({ isEnabled }) => isEnabled)?.value || ''
		: '',
);
const isInitialDataFetched = ref(false);

const pageCount = computed(() => Math.ceil(totalProductCount.value / productsPerPage.value));

const isBlockHidden = computed(() => (props.isMobileView ? props.data.mobile?.isHidden : props.data.desktop?.isHidden));
const fetchListProducts = async ({
	sort,
	page,
	categoryId = currentCategoryId.value,
	toDate = pageData.value.buildDate ? new Date(Number.parseInt(pageData.value.buildDate, 10)).toISOString() : undefined,
}: {
	sort: string;
	page: number;
	categoryId?: string;
	toDate?: string;
}) => {
	if (!ecommerceStoreId.value) {
		return;
	}

	// !IMPORTANT to reset isLoaded for animations
	// when not all page products are loaded, only the ones in cart, when this isLoaded=true,
	// animations will not be observed on mounted in BlockUser.vue because products load later and need to be watched into
	setIsLoaded(false);
	setIsLoading(true);

	try {
		const pageOffset = scrollBehaviour.value === EcommerceProductListScrollBehaviour.PAGINATION
			? (page - 1) * productsPerPage.value
			: 0;
		const productPerPageLimit = scrollBehaviour.value === EcommerceProductListScrollBehaviour.PAGINATION
			? productsPerPage.value
			: page * productsPerPage.value;

		const productDataPromises = await Promise.allSettled([
			fetchCategories(ecommerceStoreId.value, isCategoryListEnabled.value),
			StoreApi.getStoreProducts(ecommerceStoreId.value, {
				offset: pageOffset,
				limit: productPerPageLimit,
				collectionId: categoryId,
				sort: (!sort && categoryId) ? 'order=ASC&sort_by=collection_order' : sort,
				toDate,
			}),
		]);

		const productDataValues = productDataPromises.map((data) => (data.status === 'fulfilled' ? data.value : {}));
		const [categoriesResponse, productsResponse] = productDataValues as [
			{collections: EcommerceCollection[]},
			{products: EcommerceProduct[], count: number}
		];
		const fetchedProductIds = productsResponse.products.map(({ id }) => id) as string[];
		const quantity = await StoreApi.getVariantsQuantity(ecommerceStoreId.value, fetchedProductIds);

		if (categoriesResponse.collections?.length) {
			setCategories(categoriesResponse.collections.sort(
				(a: EcommerceCollection, b: EcommerceCollection) => a.title.localeCompare(b.title),
			));
		}

		products.value = productsResponse.products;
		totalProductCount.value = productsResponse.count;
		variantsQuantity.value = quantity;

		setVariantsQuantity(quantity);
	} catch (error) {
		console.error(error);
	} finally {
		setIsLoading(false);
		setIsLoaded(true);
		// set categories only when all data is fetched to prevent flashing
		setIsCategoriesLoaded(true);
	}
};

const isClientLoaded = ref(false); // needed for astro sites to not flash loader
const blockStorePageQuery = computed(() => `store-page-${props.blockId}`);

const isProductListShown = computed(() => !!products.value?.length);
const isLoading = computed(() => isAppPrerendering || isEcommerceLoading.value || !isClientLoaded.value);

const handlePageChange = (page: number) => {
	currentPage.value = page;

	fetchListProducts({
		page,
		sort: sorting.value,
		categoryId: currentCategoryId.value,
	});
};

const handleSortChange = (event: Event) => {
	const sortType = (
		event.target as HTMLInputElement
	).value as SiteEcommerceSortingValue;

	sorting.value = sortType;

	fetchListProducts({
		sort: sortType,
		page: currentPage.value,
		categoryId: currentCategoryId.value,
	});
};

const handleCategoryChange = (id: string, page?: number) => {
	currentPage.value = 1;
	currentCategoryId.value = id;

	if (!props.isInPreviewMode) {
		const href = getWebsiteCategoryUrl(id);

		window.history.pushState({}, '', href);
	}

	fetchListProducts({
		sort: sorting.value,
		page: page || currentPage.value,
		categoryId: currentCategoryId.value,
	});
};

const handleCategoryClick = (id: string) => {
	if (currentCategoryId.value === id) {
		return;
	}

	handleCategoryChange(id);
};

const handleButtonClick = async (product: EcommerceProduct) => {
	if (props.isInPreviewMode) {
		openEcommerceModal('EcommerceMessageButtonDisabled');

		return;
	}

	const hasMandatoryCustomFields = product.custom_fields?.some((field) => field.is_required);

	if (product.options.length || hasMandatoryCustomFields) {
		const itemProductPage: SitePage | undefined = Object.values(legacyProductPages.value)
			.find((page: SitePage) => page.productId === product.id);
		const ecommerceBlocks = Object.keys(props.blocks).filter((key) => props.blocks[key].type === 'BlockEcommerceProduct');
		const pageProductBlockId = props.isDynamicProductPageEnabled
			? ecommerceBlocks.find((block) => dynamicPageTemplateData.value?.blocks?.includes(block))
			: ecommerceBlocks.find((block) => itemProductPage?.blocks?.includes(block));

		if (!pageProductBlockId) {
			if (!itemProductPage) {
				window.location.assign('/');

				return;
			}

			window.location.assign(`/${product.seo_settings.slug}`);

			return;
		}

		setProductPreviewData(props.blocks[pageProductBlockId], product.id);
		openEcommerceModal('EcommerceProductPreview');

		return;
	}

	const productForShoppingCart = [
		{
			...product,
			variants: [product.variants[0]],
		},
	];

	await closeEcommerceModal();

	if (product.type.value === EcommerceProductType.BOOKING) {
		setSelectedBookingProduct(product);
		openEcommerceModal('EcommerceBookingEventSelect');

		return;
	}

	if (product.type.value === EcommerceProductType.DIGITAL) {
		const isProductAlreadyInCart = shoppingCartItems.value.some((item) => item.id === product.id);

		if (isProductAlreadyInCart) {
			setShoppingCartOpen(true);

			return;
		}
	}

	if (props.isCartVisible) {
		setShoppingCartItems([
			...shoppingCartItems.value,
			...productForShoppingCart,
		]);

		if (hasGoogleAdsApp.value) {
			googleTagManagerEvent('add_to_cart', getAddToCartEventPayload(productForShoppingCart[0]));
		}

		if (isShoppingCartOpen.value) {
			return;
		}

		setShoppingCartOpen(true);
	} else {
		setIsCheckoutLoading(true);
		await initiateCheckout(productForShoppingCart).then(() => {
			setIsCheckoutLoading(false);
		});
	}
};

const handleBrowserNavigationPageChange = () => {
	const params = new URLSearchParams(window.location.search);
	const pageParam = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams = Number.parseInt(pageParam, 10);

	if (pageFromParams === currentPage.value) {
		return;
	}

	currentPage.value = pageFromParams;
};

const handleBrowserNavigationCategoryChange = () => {
	const params = new URLSearchParams(window.location.search);
	const categoryParam = params.get('category');

	if (categoryParam === currentCategoryId.value || !categoryParam) {
		return;
	}

	currentCategoryId.value = categoryParam;

	fetchListProducts({
		sort: sorting.value,
		page: currentPage.value,
		categoryId: categoryParam || '',
	});
};

const handleWindowPopState = () => {
	handleBrowserNavigationPageChange();
	handleBrowserNavigationCategoryChange();
};

const getInitialData = () => {
	// Handle astro server side cases
	if (typeof window === 'undefined') {
		return;
	}

	const params = new URLSearchParams(window.location.search);
	const pageParam = params.get(blockStorePageQuery.value) || '1';
	const pageFromParams = Number.parseInt(pageParam, 10);
	const categoryParam = params.get('category');
	const isPageDifferent = pageFromParams !== currentPage.value;

	if (isPageDifferent && !categoryParam) {
		currentPage.value = pageFromParams;

		handlePageChange(pageFromParams);
	} else if (categoryParam) {
		const page = isPageDifferent ? pageFromParams : currentPage.value;

		handleCategoryChange(categoryParam, page);
	} else {
		fetchListProducts({
			sort: sorting.value,
			page: currentPage.value,
			categoryId: currentCategoryId.value,
		});
	}

	isInitialDataFetched.value = true;
};

onMounted(() => {
	isClientLoaded.value = true;

	window.addEventListener('popstate', () => {
		handleWindowPopState();
	});
});

onBeforeUnmount(() => {
	window.removeEventListener('popstate', handleWindowPopState);
});

// Hidden blocks in builder has only visibility: hidden so we need to check and fetch information here on demand (aka on screen resize)
watch(isBlockHidden, async () => {
	// nextTick needed because screenWidth in parent is not set yet
	// thus making the isMobile value incorrect on which the logic isBlockHidden depends
	await nextTick();

	if (!isBlockHidden.value && !isInitialDataFetched.value) {
		getInitialData();
	}
}, {
	immediate: true,
});
</script>

<template>
	<BlockEcommerceProductList
		:block-id="blockId"
		:block-style="blockStyle"
		:text-color-vars="textColorVars"
		:is-product-list-shown="isProductListShown"
		:products-per-page="productsPerPage"
		:column-count="columnCount"
		:page-count="pageCount"
		:total-product-count="totalProductCount"
		:current-page="currentPage"
		:legacy-product-pages="legacyProductPages"
		:product-category-id="currentCategoryId"
		:is-button-enabled="isButtonEnabled"
		:button-display="buttonDisplay"
		:button-text="buttonText"
		:button-style="buttonStyle"
		:button-type="buttonType"
		:button-border-width="buttonBorderWidth"
		:is-loading="isLoading"
		:is-categories-loaded="isCategoriesLoaded"
		:ribbon-style="ribbonStyle"
		:products="products"
		:translations="ecommerceTranslations"
		:image-ratio="imageRatio"
		:image-hover-effect="imageHoverEffect"
		:is-eager="lcp.type === 'block-ecommerce-product-list' && lcp.id === blockId"
		:site-id="siteId"
		:variants-quantity="variantsQuantity"
		:product-sorting="productSorting"
		:product-search="productSearch"
		:search-term="searchTerm"
		:sorting="sorting"
		:background-color="backgroundColor"
		:is-category-list-enabled="isCategoryListEnabled"
		:categories="categories"
		:is-full-width="isFullWidth"
		:is-total-product-count-shown="isTotalProductCountShown"
		:column-gap="columnGap"
		:row-gap="rowGap"
		:is-category-item-link-disabled="isInPreviewMode"
		:is-button-full-width="isButtonFullWidth"
		:is-list-centered="isListCentered"
		:is-mobile-view="isMobileView"
		:scroll-behaviour="scrollBehaviour"
		:is-cart-visible="isCartVisible"
		:can-add-to-cart="canAddToCart"
		:is-dynamic-product-page-enabled="isDynamicProductPageEnabled"
		:is-whats-app-enabled="isWhatsAppEnabled"
		:scroll-to-top-style="scrollToTopStyle"
		:is-scroll-to-top-enabled="isScrollToTopEnabled"
		:is-search-results-loading="isSearchResultsLoading"
		:search-results="formattedSearchResults"
		@page-changed="handlePageChange"
		@sort-changed="handleSortChange"
		@button-click="handleButtonClick"
		@category-click="handleCategoryClick"
		@is-dropdown-open="toggleSearchDropdown"
		@update:search-term="updateSearchTerm({ newValue: $event })"
	/>
</template>
