import {
	SiteBlocks,
	SiteBlogCategories,
	SiteEcommerceShoppingCart,
	SiteElements,
	SiteForms,
	SiteMeta,
	SiteNavItem,
	SitePages,
	SiteStyles,
	SiteElementGridGalleryImage,
	SortingOptions,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';

export interface ElasticSearchVariant {
	amount: number;
	sale_amount: number | null;
	currency_decimal_digits: number;
	currency_template: string;
	manage_inventory: boolean;
	inventory_quantity: number;
	currency_code: string;
	id: string;
}
export interface ElasticSearchResult {
	id: string;
	title: string;
	thumbnail: string;
	site_product_selection: string | null;
	variants: Array<ElasticSearchVariant>;
}

export interface SiteElementSearchItem {
	id: string;
	thumbnail: string;
	title: string;
	href: string;
	price: string;
	oldPrice: string | null;
	isInStock: boolean;
}

export interface EcommerceProductOptionValue {
	id: string;
	option_id: string;
	value: string;
	variant_id: string;
}
export interface EcommerceProductOption {
	id: string;
	title: string;
	value: EcommerceProductOptionValue;
	values: Array<EcommerceProductOptionValue>;
}
export interface EcommerceBookingEvent {
	id: string;
	buffer_time_after: number | null;
	buffer_time_before: number | null;
	length: number;
	length_unit: string;
	location: string;
	minimum_booking_notice: number | null;
	minimum_booking_notice_unit: string;
	schedule_id: string;
}
export interface EcommerceCurrency {
	code: string;
	decimal_digits: number;
	min_amount: number;
	name: string;
	name_plural: string;
	rounding: number;
	symbol: string;
	symbol_native: string;
	template: string;
}
export interface EcommerceVariantPrice {
	amount: number;
	currency_code: string;
	sale_amount: number | null;
	currency: EcommerceCurrency
}
export interface EcommerceProductVariant {
	id: string;
	image_url?: string;
	manage_inventory: boolean;
	options: Array<EcommerceProductOptionValue> | [];
	sku?: string;
	booking_event?: EcommerceBookingEvent,
	prices: Array<EcommerceVariantPrice> | [];
	title?: string;
}

export interface EcommerceProductVariantQuantity {
	id: string;
	inventory_quantity: number;
}
export interface EcommerceProductAdditionalInfo {
	id: string;
	title: string;
	description: string;
	order: number;
}

export interface EcommerceProductCustomField {
	id: string;
	title: string;
	is_required: boolean;
	value?: string;
}
export interface EcommerceProductImage {
	order: number;
	url: string;
	type: string;
}

export interface EcommerceProductSeoSettings {
	title?: string;
	noindex?: boolean;
	keywords?: string[];
	description?: string;
	focusKeyword?: string;
	ogImageOrigin?: string;
	ogImagePath?: string;
	ogImageAlt?: string;
	thumbnail?: string;
	slug?: string;
	templateId?: string;
}

export type EcommerceUpdateProductsSeoPayload = {
	id: string;
	seo_settings: EcommerceProductSeoSettings
}

export type SiteEcommerceSeoChange = {
	id: string;
	seo_settings: EcommerceProductSeoSettings;
}

export type SiteEcommerceSeoChangesSchema = SiteEcommerceSeoChange[];
export interface EcommerceCollection {
	id: string;
	title: string;
	created_at: string;
	updated_at: string;
	delete_at: string | null;
	image_url: string | null;
	metadata: Record<string, any> | null;
	store_id: string;
}
export interface EcommerceProductCollection {
	collection_id: string;
	order: number;
	product_id: string;
}

export enum EcommerceProductType {
	PHYSICAL = 'physical',
	DIGITAL = 'digital',
	DONATION = 'donation',
	BOOKING = 'booking',
	SERVICE = 'service',
}
export interface EcommerceProduct {
	id: string | number;
	description: string;
	images?: EcommerceProductImage[] | [];
	media?: EcommerceProductImage[] | []
	options: EcommerceProductOption[] | [];
	product_collections: Array<EcommerceProductCollection>;
	ribbon_text: string | null;
	site_product_selection: string | null;
	subtitle: string | null;
	thumbnail: string | null;
	title: string;
	type: {
		value: EcommerceProductType;
	};
	variants: EcommerceProductVariant[];
	purchasable?: boolean;
	additional_info: EcommerceProductAdditionalInfo[];
	seo_settings: EcommerceProductSeoSettings;
	custom_fields?: EcommerceProductCustomField[];
}

export interface EcommerceQuantifiedProductData extends EcommerceProduct {
	variants: Array<EcommerceProductVariant & {
		booking_event: {
			time_slot: string,
			date: Date | string,
			length: number,
			length_unit: string
		}
	}>
}
export interface EcommerceQuantifiedItem {
	product: EcommerceQuantifiedProductData,
	quantity: number
}

export interface EcommerceCountry {
	countryCode: string,
	provinces: string[]
}

export interface EcommerceRegion {
	id: string;
	countries: EcommerceCountry[]
}

export const ECOMMERCE_SHIPPING_PROVIDER_TYPE = {
	DPD: 'dpd',
	OMNIVA: 'omniva',
	LP_EXPRESS: 'lp-express',
	MANUAL: 'manual',
} as const;

export type EcommerceShippingProviderType = typeof ECOMMERCE_SHIPPING_PROVIDER_TYPE[keyof typeof ECOMMERCE_SHIPPING_PROVIDER_TYPE]

export interface EcommerceShippingOption {
	id: string,
	displayName: string,
	provider: EcommerceShippingProviderType,
	amount: number
}

export interface EcommerceCart {
	id: string;
	email: string | null;
	billing_address_id: string;
	billing_address: {
		id: string;
		created_at: string;
		updated_at: string;
		deleted_at: string | null;
		company: string | null;
		name: string | null;
		address_1: string | null;
		address_2: string | null;
		city: string | null;
		country_code: string;
		province_code: string;
		province: {
			iso_2: string;
			country_code: string;
			name: string;
			display_name: string;
		};
		postal_code: string | null;
		phone: string | null;
		metadata: any | null;
	};
	shipping_address_id: string;
	shipping_address: {
		id: string;
		created_at: string;
		updated_at: string;
		deleted_at: string | null;
		company: string | null;
		name: string | null;
		address_1: string | null;
		address_2: string | null;
		city: string | null;
		country_code: string;
		province_code: string;
		province: {
			iso_2: string;
			country_code: string;
			name: string;
			display_name: string;
		};
		postal_code: string | null;
		phone: string | null;
		metadata: any | null;
	};
	items: Array<{
		id: string;
		cart_id: string;
		tax_lines: any[];
		order_id: string | null;
		booking_id: string | null;
		type_id: string;
		title: string;
		description: string;
		thumbnail: string;
		is_return: boolean | null;
		should_merge: boolean;
		allow_discounts: boolean;
		has_shipping: boolean;
		unit_price: number;
		variant_id: string;
		variant: {
			id: string;
			title: string;
			image_url: string | null;
			product_id: string;
			sku: string | null;
			inventory_quantity: number;
			allow_backorder: boolean;
			manage_inventory: boolean;
			weight: number | null;
			created_at: string;
			updated_at: string;
			deleted_at: string | null;
		};
		quantity: number;
		fulfilled_quantity: number | null;
		returned_quantity: number | null;
		shipped_quantity: number | null;
		sku: string | null;
		adjustments: any[];
		created_at: string;
		updated_at: string;
		metadata: any;
		custom_field_values: any[];
		unit_total: number;
		unit_tax: number;
		unit_subtotal: number;
		unit_discount: number;
		unit_original_tax: number;
		unit_original_total: number;
		unit_original_subtotal: number;
		discount_total: number;
		tax_total: number;
		subtotal: number;
		total: number;
	}>;
	region_id: string;
	region: {
		id: string;
		name: string;
		currency_code: string;
		currency: {
			code: string;
			symbol: string;
			symbol_native: string;
			name: string;
			name_plural: string;
			decimal_digits: number;
			rounding: number;
			template: string;
			min_amount: number;
			max_amount: number;
			is_enabled: boolean;
		};
		tax_rate: number;
		tax_code: string | null;
		created_at: string;
		updated_at: string;
		deleted_at: string | null;
		metadata: any | null;
		store_id: string;
		countries: Array<{
			iso_2: string;
			iso_3: string;
			num_code: number;
			name: string;
			display_name: string;
			measurement_system: string;
			provinces: Array<{
				iso_2: string;
				country_code: string;
				name: string;
				display_name: string;
			}>;
		}>;
	};
	tax_region_id: string | null;
	store: {
		id: string;
		name: string;
		currency: {
			code: string;
			symbol: string;
			symbol_native: string;
			name: string;
			name_plural: string;
			decimal_digits: number;
			rounding: number;
			template: string;
			min_amount: number;
			max_amount: number;
			is_enabled: boolean;
		};
		checkout_fields: any[];
		settings: {
			checkout_collect_phone: boolean;
			measurement_system: string;
			invoice_title: string;
			invoice_next_number: number;
			invoice_custom_fields: any[];
			terms_and_conditions_url: string;
			privacy_policy_url: string;
			invoice_logo_url: string;
			is_invoice_logo_shown: boolean;
			is_invoice_email_shown: boolean;
			is_invoice_phone_shown: boolean;
			tax_model: string;
		};
		store_payment_providers: Array<{
			id: string;
			store_id: string;
			title: string;
			description: string | null;
			payment_provider_id: string;
			external_id: string | null;
		}>;
		enabled_features: {
			gift_card: boolean;
		};
	};
	store_id: string;
	discounts: any[];
	gift_card_usages: any[];
	payment_sessions: any[];
	payment_id: string | null;
	payment: any | null;
	shipping_methods: any[];
	checkout_field_values: any[];
	type: string;
	completed_at: string | null;
	payment_authorized_at: string | null;
	created_at: string;
	updated_at: string;
	deleted_at: string | null;
	metadata: any | null;
	idempotency_key: string | null;
	context: {
		ip: string;
		locale: string;
		tax_model: string;
		time_zone: string;
		cancel_url: string;
		user_agent: string;
		is_physical: boolean;
		success_url: string;
	};
	shipping_total: number;
	item_original_total: number;
	discount_total: number;
	gift_card_total: number;
	tax_total: number;
	total: number;
	original_tax_total: number;
	item_tax_total: number;
	subtotal: number;
	original_item_subtotal: number;
	original_item_tax_total: number;
	original_total: number;
}

export interface EcommerceCartPayloadCustomField {
	custom_field_id: string,
	value: string
}

export interface EcommerceCartPayloadItem {
	variant_id: string,
	quantity: number,
	custom_field_values?: EcommerceCartPayloadCustomField[]
}
export interface EcommerceCreateCartPayload {
	items: EcommerceCartPayloadItem[],
	successUrl: string,
	cancelUrl: string,
	locale: string,
	storeId: string,
}
export interface EcommerceCartUpdatePayload {
	shippingAddress: {
		country?: string,
		state?: string,
		line1?: string,
		city?: string,
		name?: string,
		phone?: string,
		postal_code?: string
	},
	billingAddress: {
		country?: string,
		state?: string,
		line1?: string,
		city?: string,
		name?: string,
		phone?: string,
		postal_code?: string
	},
	regionId?: string
}
export interface EcommerceProductSeoSettingsData {
	id: string;
	title: string;
	lowest_amount: number;
	highest_amount: number;
	is_hidden?: boolean;
	currency: {
		code: string;
		template: string;
		decimal_digits: number;
	};
	seo_settings: EcommerceProductSeoSettings;
	thumbnail?: string,
}

export interface EcommerceProductPage {
	productId: string;
	slug: string;
}

export interface EcommerceBookingTimeSlot {
	time_zone: string,
	slots: string[]
}

export interface EcommerceBookingAvailability {
	time_zone: string,
	disabled_dates: string[]
}

export interface EcommerceStoreSettings {
	store_owner: {
		language: string
	},
	stripe_public_key?: string,
	stripe_account_id?: string
}

export type UpsertRegionCountry = {
	country_code: string
	provinces: string[]
}

export interface SiteLanguageSwitcherLanguage {
	country: string;
	flagPath: string;
	href: string;
	isHidden: boolean;
	locale: string;
	name: string;
}

export interface SiteBlockHeaderItem {
	id: string;
	name: string;
	url: string;
	isCurrent: boolean;
	href: string;
	target: string;
	rel: string;
	hasDropdown: boolean;
	subItems: SiteBlockHeaderItem[];
}

export interface GridSocialIconLink {
	link: string;
	icon: string;
	svg: string;
}

export interface EcommerceProductSortingOption {
	id: SortingOptions;
	isEnabled: boolean;
	value: string;
	label: string;
}

export interface EcommerceProductSorting {
	enabled: boolean;
	textColor?: string;
	sortingOptions?: EcommerceProductSortingOption[];
}
export interface EcommerceProductSearch {
	isEnabled: boolean;
	placeholderText: string;
	noResultsText: string;
	fillColor?: string;
	textAndIconColor?: string;
	borderColor?: string;
	borderRadius?: number;
	inputAlignment?: string;
	width?: number;
	resultItemHoverColor?: string;
}

export interface SitePageData {
	pages: SitePages,
	blocks: SiteBlocks,
	elements: SiteElements,
	nav: SiteNavItem[],
	currentLocale: string,
	homePageId: string,
	isNavHidden: boolean,
	cookieBannerAcceptText?: string,
	cookieBannerDisclaimer?: string,
	cookieBannerDeclineText?: string,
	blogReadingTimeText?: string,
	metaTitle?: string,
	meta: SiteMeta,
	forms: SiteForms,
	styles: SiteStyles,
	domain?: string,
	siteId?: string,
	ecommerceShoppingCart?: SiteEcommerceShoppingCart,
	blogCategories: SiteBlogCategories,
	languageSwitcherLanguages: SiteLanguageSwitcherLanguage[],
	currentPageId: string,
	productId?: string | number,
	languageKeys: string[],
	isDynamicProductPageEnabled?: boolean,
	buildDate?: string;
}

export const GOOGLE_CONVERSION_TRACKING_TYPE = {
	TYPE_CONVERSION_EVENT: 'TYPE_CONVERSION_EVENT',
	TYPE_PAGE_VIEW: 'TYPE_PAGE_VIEW',
} as const;

export const GOOGLE_TAG_MANAGER_EVENT = {
	SUBSCRIBE: 'subscribe',
	BEGIN_CHECKOUT: 'begin_checkout',
	PURCHASE: 'purchase',
	SIGN_UP: 'sign_up',
	CONTACT: 'contact',
	ADD_TO_CART: 'add_to_cart',
} as const;

export type GoogleTagManagerEventName = typeof GOOGLE_TAG_MANAGER_EVENT[keyof typeof GOOGLE_TAG_MANAGER_EVENT];

export interface GoogleTagManagerEventPayloadMap {
	[GOOGLE_TAG_MANAGER_EVENT.SUBSCRIBE]: null;
	[GOOGLE_TAG_MANAGER_EVENT.CONTACT]: null;
	[GOOGLE_TAG_MANAGER_EVENT.BEGIN_CHECKOUT]: {
		value: number;
		currency: string;
		items: any[];
	};
	[GOOGLE_TAG_MANAGER_EVENT.PURCHASE]: {
		value: number;
		currency: string;
		transaction_id: string;
		items: any[];
		shipping: number;
		tax: number;
	};
	[GOOGLE_TAG_MANAGER_EVENT.SIGN_UP]: {
		method: string;
	};
	[GOOGLE_TAG_MANAGER_EVENT.ADD_TO_CART]: {
		value: number;
		currency: string;
		items: any[];
	};
}
export interface ComposedGalleryImage extends SiteElementGridGalleryImage {
	src: string;
	srcset: string;
	sizes: string;
	width: number;
	height: number;
}

// Ecommerce stripe callback events
export interface StripeAddress {
	city: string;
	country: string;
	line1: string;
	line2: string;
	postal_code: string;
	state: string;
}

export interface StripeShippingRate {
	amount: number;
	displayName: string;
	id: string; // so_000xxx
	provider: string;
}

export interface StripeAddressEvent {
	name: string;
	address: StripeAddress;
	resolve: (payload: { shippingRates: StripeShippingRate[] }) => void;
	reject: () => void;
}

export interface StripeClickEvent {
	elementType: string;
	expressPaymentType: string;
	resolve: (payload: {
		shippingAddressRequired: boolean;
		allowedShippingCountries?: string[];
		shippingRates: StripeShippingRate[]
	}) => void;
}

export interface StripeShippingRateEvent {
	shippingRate: StripeShippingRate;
	resolve: (payload?: { shippingRates: StripeShippingRate[] }) => void;
	reject: () => void;
}

export interface StripeConfirmEvent {
	elementType: string;
	expressPaymentType: string;
	paymentFailed: (payload: { reason: 'fail' | 'invalid_shipping_address' }) => void;
	shippingAddress?: {
		name: string;
		address: StripeAddress;
	};
	shippingRate?: StripeShippingRate;
	billingDetails: {
		name: string;
		address: StripeAddress;
	};
}
