<template>
	<div>
		<ZyroPagination
			v-if="scrollBehaviour === EcommerceProductListScrollBehaviour.PAGINATION"
			:current-page="currentPage"
			:page-count="pageCount"
			class="product-list-pagination"
			color="var(--body-color)"
			@change-page="handlePageChange($event)"
		/>
		<template v-else>
			<div
				ref="infiniteScrollTriggerRef"
				class="infinite-scroll-trigger"
			/>
			<template v-if="isLoading">
				<div class="loader">
					<div class="loader__element" />
					<div class="loader__element" />
					<div class="loader__element" />
					<div class="loader__element" />
					<div class="loader__element" />
				</div>
			</template>
		</template>
	</div>
</template>

<script setup lang="ts">
import ZyroPagination from '@zyro-inc/site-modules/components/ZyroPagination.vue';
import { EcommerceProductListScrollBehaviour } from '@hostinger/builder-schema-validator/schema/schemaTypes';
import {
	nextTick,
	onMounted,
	ref,
	watch,
} from 'vue';

const props = withDefaults(defineProps<{
	blockId: string;
	isLoading?: boolean;
	scrollBehaviour: EcommerceProductListScrollBehaviour;
	currentPage: number;
	pageCount: number;
}>(), {
	isLoading: false,
	scrollBehaviour: EcommerceProductListScrollBehaviour.PAGINATION,
});

const emit = defineEmits<{
	'page-changed': [number];
	'set-is-animation-active': [boolean];
}>();
const scrollObserver = ref<IntersectionObserver>(null as unknown as IntersectionObserver);
const infiniteScrollTriggerRef = ref<HTMLElement | null>(null);

const handlePageChange = async (page: number) => {
	// reset animation first so it would be re-triggered on page change
	emit('set-is-animation-active', false);
	emit('page-changed', page);

	await nextTick();

	emit('set-is-animation-active', true);

	const block = document.getElementById(props.blockId);
	const blockRect = block?.getBoundingClientRect();
	const isInViewport = blockRect && blockRect.top >= 0 && blockRect.bottom <= window.innerHeight;

	if (!isInViewport) {
		block?.scrollIntoView({
			behavior: 'smooth',
		});
	}
};

const handleIntersect = async (isIntersecting: boolean) => {
	if (!isIntersecting || props.currentPage >= props.pageCount) {
		return;
	}

	emit('page-changed', props.currentPage + 1);
};

const observeScroll = () => {
	scrollObserver.value.observe(infiniteScrollTriggerRef.value as HTMLElement);
};

const addScrollIntersectionObserver = () => {
	scrollObserver.value = new IntersectionObserver(([{ isIntersecting }]) => {
		handleIntersect(isIntersecting);
	}, {
		threshold: 1,
	});

	if (!infiniteScrollTriggerRef.value) {
		return;
	}

	observeScroll();
};

onMounted(() => {
	addScrollIntersectionObserver();
});

// to trigger in user site after product fetching
watch(() => props.isLoading, async (newValue) => {
	await nextTick();

	if (newValue) {
		scrollObserver.value?.disconnect();
	} else {
		observeScroll();
	}
});

// to trigger in block after scroll behaviour changed in settings
watch(() => props.scrollBehaviour, async (newValue) => {
	await nextTick();

	if (newValue === EcommerceProductListScrollBehaviour.PAGINATION) {
		scrollObserver.value?.disconnect();
	} else {
		observeScroll();
	}
});
</script>

<style scoped lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.product-list-pagination {
	margin-top: 16px;

	:deep(.pagination__trigger--current),
	:deep(button:hover),
	:deep(button:focus) {
		color: var(--body-color);
		text-decoration: underline;
		filter: brightness(0.8)
	}
}

@include site-engine-mobile{
	.product-list-pagination {
		margin-top: 16px;

		:deep(.pagination__trigger--current),
		:deep(button:hover),
		:deep(button:focus) {
			color: var(--body-m-color, var(--body-color));
		}
	}
}

.loader {
	margin: 16px 0;
	position: relative;
	width: 20px;
	height: 20px;

	&__element {
		position: absolute;
		top: 0;
		left: 0;
		box-sizing: border-box;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		border: 4px solid;
		border-color: var(--body-color) transparent transparent transparent;
		animation: spin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	}

	&__element:nth-child(1) {
		animation-delay: -0.45s;
	}

	&__element:nth-child(2) {
		animation-delay: -0.3s;
	}

	&__element:nth-child(3) {
		animation-delay: -0.15s;
	}

	&__element:nth-child(4) {
		opacity: 0.2;
		animation: none;
		border-color: var(--body-color);
	}
}

@keyframes spin {
	to {
		transform: rotate(360deg);
	}
}
</style>
