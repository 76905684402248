<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps<{
	scrollToTopStyle?: {
		backgroundColor: string,
		contrastBackgroundColor: string
	},
	isWhatsAppEnabled?: boolean,
}>();

defineEmits<{
	'scroll-to-top': []
}>();

const positionTopDesktop = computed(() => (props.isWhatsAppEnabled ? '145px' : '75px'));
const positionTopMobile = computed(() => (props.isWhatsAppEnabled ? '145px' : '60px'));
const backgroundColor = computed(() => props.scrollToTopStyle?.backgroundColor || 'white');
const contrastBackgroundColor = computed(() => props.scrollToTopStyle?.contrastBackgroundColor || 'black');
</script>

<template>
	<button
		class="scroll-to-top"
		@click="$emit('scroll-to-top')"
	>
		<svg
			width="8"
			height="14"
			viewBox="0 0 8 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M1 13L7 7L1 1"
				stroke="currentColor"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	</button>
</template>

<style scoped lang="scss">
@import "@zyro-inc/site-modules/scss/mixins/site-engine-mobile";

.scroll-to-top {
	position: sticky;
	top: calc(100% - v-bind(positionTopDesktop));
	display: flex;
	z-index: 1000;
	left: calc(100% - 60px);
	color: v-bind(contrastBackgroundColor);
	padding: 18px 21px;
	border-radius: 50%;
	box-shadow: 0 6px 14px rgb(0 0 0 / 20%);
	background-color: v-bind(backgroundColor);
	cursor: pointer;

	svg {
		transform: rotate(270deg);
	}
}

@include site-engine-mobile {
	.scroll-to-top {
		top: calc(100% - v-bind(positionTopMobile));
	}
}
</style>
